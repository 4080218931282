const CDK = {
  init(option) {
    return new Promise((resolve, reject) => {
      this.getComponent().then((Constructor) => {
        const instance = new Constructor(option);
        resolve(instance);
      })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getComponent() {
    return new Promise((resolve, reject) => {
      import(/* webpackChunkName: "cdk"*/ './cdk.js').then(({ default: CDK }) => {
        resolve(CDK);
      }).catch((err) => {
        reject(err);
      });
    });
  },
};

export default CDK;
