const tpl = iFlowId => `
<div id="shipAddress_${iFlowId}" style="display:none;">
    <div class="gift-ship-address-dialog-mask"></div>
    <div class="gift-ship-address-dialog-container">
        <div class="gift-ship-address-dialog-header">
        实物中奖个人信息
        <a href="###" class="close-btn close-dialog">关闭</a>
        </div>
        <p class="hint">个人信息填写:为保证实物奖励能准确邮寄，下面所有项目务必详细填写！<span>未获得实物奖励请勿填写</span></p>
        <form id="shipAddress_form_${iFlowId}" class="content">   
            <div  class= "line">
                <label class="must">所获实物</label>
                <div class="form-item"><select name="sPackageInfo" id="package_${iFlowId}"></select></div>
            </div>
            <div class= "line">
                <label class="must">姓名</label>
                <div class="form-item"><input name="sName" type="text" class="input-box"/></div>
            </div>
            <div class= "line">
                <label>性别</label> 
                <div class="form-item check-box">
                    <div class="check-item">
                        <span>男：<input style="width:14px;" name="iGender" value="1" type="radio" checked="checked"></span>
                    </div>
                    <div class="check-item">
                        <span>女：<input style="width:14px;" name="iGender" value="0" type="radio"></span>
                    </div>
                </div>
            </div>
            <div class= "line">
                <label class="must">身份证</label> 
                <div class="form-item"><input name="sIdentity" type="text" class="input-box"></div>
            </div>
            <div class= "line">
                <label class="must">手机</label> 
                <div class="form-item"><input name="sMobile" type="text" class="input-box"></div>
            </div>
            <div class= "line">
                <label class="must">省份</label>
                <div class="form-item"><select name="sProvince" id="province_${iFlowId}" type="text"></select></div>
            </div>
            <div class= "line">
                <label class="must">城市</label>
                <div class="form-item"><select name="sCity" id="city_${iFlowId}" type="text"></select></div>
            </div>
            <div class= "line">
                <label>区/县</label>
                <div class="form-item"><select name="sExtend2" id="county_${iFlowId}" type="text"></select></div>
            </div>
            <div class= "line">
                <label class="must">详细地址</label>
                <div class="form-item"><input name="sAddress" type="text" class="input-box"></div>
            </div>
            <div class= "line">
                <label >邮编</label>
                <div class="form-item"><input name="sPostCode" type="text" class="input-box"></div>
            </div>
            <div class= "line order-line">
                <label>发货订单</label>
                <div id="order_${iFlowId}" class="order-info">暂无</div>
            </div>
            <div class='btn-container'>
                <a id="submitBtn_${iFlowId}" class='btn submit-btn'>提交</a>
                <a class='btn cancel-btn close-dialog'>取消</a>
            </div>    
        </form>
    </div>
</div>
`;
export default tpl;
