import tpl from './tpl';
import { appendHtml } from '../util/domHelper';
import './style.css';


let $loader = null;
export const loadStart = (hint) => {
  if (!$loader) {
    const template = tpl.replace('{hint}', hint);
    $loader =  appendHtml(
      document.body,
      template,
      'milo-loading-container',
      'milo-loading-container',
    );
  } else {
    $loader.style.display = 'block';
  }
};

export const loadStop = () => {
  if (!$loader) {
    return;
  }
  $loader.style.display = 'none';
};
