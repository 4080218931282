const FormManager = {};
/**
 * 获取form对象（明显是一鸡肋）
 * @private
 * @param {string} dom id
 * @return {dom} dom对象
 */
const getContextDom = function (contextId) {
  let context = null;
  if (typeof(contextId) === 'string') {
    context = document.getElementById(contextId);
  } else if (typeof(contextId) === 'object' && contextId) {
    context = contextId;
  }
  if (!context) {
    context = document;
  }
  return context;
};

const getInputTagValue = function (_dom) {
  const submitData = {};
  const allInput = _dom.getElementsByTagName('input');
  if (!allInput || !allInput.length) { // 无输入框
    return;
  }

  for (let i = 0; i < allInput.length; i++) {
    const tempDom = allInput[i];
    const name_ = tempDom.getAttribute('name');
    const value_ = tempDom.value || '';
    let type_ = tempDom.getAttribute('type');
    if (!name_) { // 先看是否包含name属性
      continue;
    }

    if (!type_) {
      type_ = 'text';
    }
    type_ = type_.toLowerCase();


    // 如果为button
    if (type_ == 'button') {
      continue;
    }

    // 如果为radio
    if (type_ == 'radio') {
      if (tempDom.checked) {
        submitData[name_] = value_;
      } else {
        if (typeof(submitData[name_]) === 'undefined') {
          submitData[name_] = '';
        }
      }
    } else if (type_ == 'checkbox') { // 复选框的返回值保存在一个数组内
      if (typeof(submitData[name_]) === 'undefined') {
        submitData[name_] = [];
      }
      if (tempDom.checked) {
        submitData[name_].push(value_);
      }
    } else {
      submitData[name_] = value_;
    }
  }
  return submitData;
};

const getSelectTagValue = (_dom) => {
  const submitData = {};
  const allSelect = _dom.getElementsByTagName('select');
  if (allSelect.length > 0) {
    for (let i = 0; i < allSelect.length; i++) {
      const tempDom = allSelect[i];
      const name_ = tempDom.getAttribute('name');
      if (!name_) { // 先看是否包含name属性
        continue;
      }
      const value_ = [];
      const allOption = tempDom.getElementsByTagName('option');
      for (let j = 0; j < allOption.length; j++) {
        const temp = allOption[j];
        const select_ = temp.selected;
        const optionValue = temp.value || '';

        if (select_) { // 存在select多选的情况
          value_.push(optionValue);
        }
      }
      if (value_.length == 0) {
        submitData[name_] = '';
        continue;
      }
      if (value_.length == 1) { // 单选
        submitData[name_] = value_[0];
        continue;
      }
      submitData[name_] = value_;
    }
  }
  return submitData;
};

const getTextAreaValue = (_dom) => {
  const submitData = {};
  const allTextarea = _dom.getElementsByTagName('textarea');
  if (allTextarea.length > 0) {
    for (let i = 0; i < allTextarea.length; i++) {
      const tempDom = allTextarea[i];
      const name_ = tempDom.getAttribute('name');
      if (!name_) { // 先看是否包含name属性
        continue;
      }

      const value_ = tempDom.value || '';

      submitData[name_] = value_;
    }
  }
  return submitData;
};
/**
 * 取得所有表单的值
 * @param {string} dom id
 * @return {dom} dom对象
 */
FormManager.getAllInputValue = function (contextId) {
  const _dom = getContextDom(contextId);

  const inputTagValue = getInputTagValue(_dom);

  const selectTagValue = getSelectTagValue(_dom);

  const textAreaValue = getTextAreaValue(_dom);

  return {
    ...inputTagValue,
    ...selectTagValue,
    ...textAreaValue,
  };
};

/**
 * 根据名字取得表单值
 * @param {string} name 表单name
 * @param {string} dom id
 * @return {string} 表单值
 */
FormManager.getInputValueByName = function (name, contextId) {
  const allInputValue = FormManager.getAllInputValue(contextId);
  return allInputValue[name] || '';
};

/**
 * 修改时填充某Form ID下所有表单的值,结构不能太复杂
 *  var json = {
 * 		text1 : 'abc',
 * 		text3 : '3',
 * 		text4 : ['5','6','7'],
 * 		text5 : '9',
 * 		text6 : 'uuuuuuuukkkkkk',
 *  	text7 : '10'
 *  };
 * @param {object} 表单对象 以key-value形式对应
 * @param {string} dom id
 * @return {dom} dom对象
 */
FormManager.setAllInputValue = function (json, contextId) {
  if (!json) {
    return false;
  }
  const dom = getContextDom(contextId);

  { // 所有input框，checkbox,radio
    const allInput = dom.getElementsByTagName('input');
    if (allInput.length > 0) {
      for (let i = 0; i < allInput.length; i++) {
        const tempDom = allInput[i];
        const name = tempDom.getAttribute('name');
        if (!name) { // 先看是否包含name属性
          continue;
        }
        if (typeof(json[name]) === 'undefined') {
          continue;
        }
        let value = json[name];

        let type = tempDom.getAttribute('type');
        if (!type) {
          type = 'text';
        }
        type = type.toLowerCase();

        { // 如果为button
          if (type == 'button') {
            continue;
          }
        }

        { // 如果为radio
          if (type == 'radio') {
            const value1 = tempDom.value;

            if (value1 == value) {
              tempDom.setAttribute('checked', 'checked');
              tempDom.checked = 'checked';
            } else {
              tempDom.removeAttribute('checked');
              tempDom.checked = '';
            }
            continue;
          }
        }

        { // 如果为checkbox
          if (type == 'checkbox') {
            if (typeof(value) === 'string') {
              value = [value];
            }
            const value1 = tempDom.value;

            let flag = false;
            for (let j = 0; j < value.length; j++) {
              const v = value[j];
              if (value1 == v) {
                tempDom.setAttribute('checked', 'checked');
                tempDom.checked = 'checked';

                flag = true;
                break;
              }
            }
            if (!flag) {
              tempDom.removeAttribute('checked');
              tempDom.checked = '';
            }
            continue;
          }
        }

        // 后面所有的都为text
        tempDom.setAttribute('value', value);
        tempDom.value = value;
      }
    }
  }


  { // 取得所有select框
    const allSelect = dom.getElementsByTagName('select');
    if (allSelect.length > 0) {
      for (let i = 0; i < allSelect.length; i++) {
        const tempDom = allSelect[i];
        const name = tempDom.getAttribute('name');
        if (!name) { // 先看是否包含name属性
          continue;
        }
        if (typeof(json[name]) === 'undefined') {
          continue;
        }
        let value = json[name];
        if (typeof(value) === 'string') {
          value = [value];
        }

        const allOption = tempDom.getElementsByTagName('option');
        if (allOption.length == 0) {
          continue;
        }

        let selFlag = false;
        for (let j = 0; j < allOption.length; j++) {
          const temp = allOption[j];
          const value1 = temp.value;


          let flag = false;
          for (let k = 0; k < value.length; k++) {
            const t = value[k];
            if (t == value1) {
              temp.setAttribute('selected', 'selected');
              temp.selected = 'selected';
              flag = true;
              selFlag = true;
              break;
            }
          }
          if (!flag) { // 存在select多选的情况
            temp.removeAttribute('selected');
            temp.selected = '';
          }
        }

        if (!selFlag) { // 如果一个都没选择的话
          // 如果是单选的话
          const multiple = tempDom.getAttribute('multiple');
          if (!multiple) {
            allOption[0].setAttribute('selected', 'selected');
            allOption[0].selected = 'selected';
          }
        }
      }
    }
  }

  { // 取得所有textarea框
    const allTextarea = dom.getElementsByTagName('textarea');
    if (allTextarea.length > 0) {
      for (let i = 0; i < allTextarea.length; i++) {
        const tempDom = allTextarea[i];
        const name = tempDom.getAttribute('name');
        if (!name) { // 先看是否包含name属性
          continue;
        }
        if (typeof(json[name]) === 'undefined') {
          continue;
        }
        tempDom.setAttribute('value', json[name]);
        tempDom.value = json[name];
      }
    }
  }

  return true;
};

export default FormManager;
