import tpl from './tpl';
import tplMobile from './tplMobile.js';
import { findNodeByClass, appendHtml } from '../util/domHelper';
import { miloCore } from '@tencent/milo-core';
import './style.css';

const isMobile = miloCore.platform.isMobile();
class AlertDialog {
  constructor(option) {
    this.renderKey = `${Math.ceil(Math.random() * 10000)}_${new Date().getTime()}`;
    this.option = {
      message: '',
      type: 'info',
      title: '提示',
      ...option,
    };
  }
  alert() {
    const { message, title, isHtml, confirmTitle, cancelTitle, onConfirm, onClose, onCancel } = this.option || {};
    let alertHtml = tpl.replace(/\{message\}/g, isHtml ? message : miloCore.xss.filter(message));
    alertHtml = alertHtml.replace(/\{title\}/g, isHtml ? title : miloCore.xss.filter(title));
    const $selector = appendHtml(document.body, alertHtml, `${this.renderKey}-milo-alert`, 'milo-alert-frame');
    findNodeByClass($selector, 'btn-confirm')[0].onclick = () => {
      if ($selector) {
        $selector.parentNode.removeChild($selector);
      }
      onConfirm && onConfirm();
    };

    if (miloCore.isString(cancelTitle) && cancelTitle) {
      const $btnCancel = findNodeByClass($selector, 'btn-cancel')[0];
      $btnCancel.style.display = 'block';
      $btnCancel.innerText = cancelTitle;
    }
    if (miloCore.isString(confirmTitle) && confirmTitle) {
      findNodeByClass($selector, 'btn-confirm')[0].innerText = confirmTitle;
    }
    findNodeByClass($selector, 'btn-cancel')[0].onclick = () => {
      if ($selector) {
        $selector.parentNode.removeChild($selector);
      }
      onCancel && onCancel();
    };
    findNodeByClass($selector, 'milo-alert-close-btn')[0].onclick = () => {
      if ($selector) {
        $selector.parentNode.removeChild($selector);
      }
      onClose && onClose();
    };
  }
  alertMobile() {
    const { message, title, isHtml, confirmTitle, confirmColor, cancelTitle,
      cancelColor, onConfirm, onCancel } = this.option || {};
    let alertHtml = tplMobile.replace(/\{message\}/g, isHtml ? message : miloCore.xss.filter(message)).replace(/\{title\}/g, isHtml ? title : miloCore.xss.filter(title));
    alertHtml = alertHtml.replace(/\{cancelColor\}/g, cancelColor || '#000');
    alertHtml = alertHtml.replace(/\{confirmColor\}/g, confirmColor || '#0f4379');
    alertHtml = alertHtml.replace(/\{confirmTitle\}/g, (isHtml ? confirmTitle : miloCore.xss.filter(confirmTitle)) || '确定');
    alertHtml = alertHtml.replace(/\{cancelTitle\}/g, (isHtml ? cancelTitle : miloCore.xss.filter(cancelTitle)) || '取消');
    const $selector = appendHtml(document.body, alertHtml, `${this.renderKey}-milo-alert`, 'milo-alert-frame');
    if (title === '') {
      findNodeByClass($selector, 'milo-alert-header')[0].style.display = 'none';
    }
    if (miloCore.isString(cancelTitle) && cancelTitle) {
      findNodeByClass($selector, 'btn-cancel')[0].style.display = 'block';
    }
    findNodeByClass($selector, 'btn-confirm')[0].onclick = () => {
      if ($selector) {
        $selector.parentNode.removeChild($selector);
      }
      onConfirm && onConfirm();
    };
    findNodeByClass($selector, 'btn-cancel')[0].onclick = () => {
      if ($selector) {
        $selector.parentNode.removeChild($selector);
      }
      onCancel && onCancel();
    };
  }
}

export const miloAlert = (option) => {
  let message;
  let type;
  let cancelTitle;
  let cancelColor;
  let confirmTitle;
  let confirmColor;
  let isHtml;
  if (typeof option === 'string' || typeof option === 'number') {
    message = option;
    option = {};
    type = 'info';
  } else {
    if (!option || !option.message) {
      throw 'miloAlert入参错误：请传入message';
    } else {
      message = option.message;
      type = option.type;
      cancelTitle = option.cancelTitle;
      cancelColor = option.cancelColor;
      confirmTitle = option.confirmTitle;
      confirmColor = option.confirmColor;
      isHtml = option.isHtml;
    }
  }
  const dialog = new AlertDialog({
    title: option.title || (isMobile ? '' : '提示'),
    message,
    cancelTitle,
    cancelColor,
    confirmTitle,
    confirmColor,
    type,
    isHtml,
    onConfirm() {
      option && option.onConfirm && option.onConfirm();
    },
    onClose() {
      option && option.onClose && option.onClose();
    },
    onCancel() {
      option && option.onCancel && option.onCancel();
    },
  });
  if (isMobile) {
    dialog.alertMobile();
  } else {
    dialog.alert();
  }
};
