const DJCPay = {
  pay(option) {
    return new Promise((resolve, reject) => {
      this.getComponent().then((Constructor) => {
        const instance = new Constructor(option);
        return instance.pay();
      })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getComponent() {
    return new Promise((resolve, reject) => {
      import(/* webpackChunkName: "djcpay"*/ './djcpay.js').then(({ default: DJCPay }) => {
        resolve(DJCPay);
      }).catch((err) => {
        reject(err);
      });
    });
  },
};

export default DJCPay;
