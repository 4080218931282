const Customize = {
  submit(option) {
    return new Promise((resolve, reject) => {
      this.getComponent().then((Constructor) => {
        const instance = new Constructor(option);
        return instance.submit();
      })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getComponent() {
    return new Promise((resolve, reject) => {
      import(/* webpackChunkName: "customize"*/ './customize.js').then(({ default: Customize }) => {
        resolve(Customize);
      }).catch((err) => {
        reject(err);
      });
    });
  },
};

export default Customize;
