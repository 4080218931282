import RoleSelector from './RoleSelector';
import RoleSelectorMobile from './RoleSelectorMobile';
import AreaBind from './AreaBind';
import CDK from './CDK';
import CheckIn from './CheckIn';
import ProvinceCitySelector from './CitySelector';
import Lottery from './Lottery';
import LotteryBroadcast from './LotteryBroadcast';
import MyGiftList from './MyGiftList';
import ShipAddress from './ShipAddress';
import ShipAddressTpl from './ShipAddress/tpl';
import Login from './Login';
import FormManager from './util/form';
import Customize from './Customize';
import { miloAlert } from './AlertDialog';
import Dialog from './Dialog';
import { loadStart, loadStop } from './Loader';
import DJCPay from './DJCPay';
import { miloCore, FrontMonitor, mobileShare, iosInstallReport, ATM } from '@tencent/milo-core';
ATM.setFromModule(5);
if (!window.No_FrontMonitor_Auto) {
  FrontMonitor.init();
} else {
// 只监控milo相关的异常数据
  FrontMonitor.initForMilo();
}// 加载模块版本控制文件
// document.write(`${'<scrip' + 't src="//ossweb-img.qq.com/images/js/miloweb/umd/v0.0.1/milo_module_version.js?r='}${Math.ceil(Math.random() * 1000000)}"></scrip` + 't>');
export default {
  Login,
  RoleSelector,
  RoleSelectorMobile,
  AreaBind,
  CDK,
  CheckIn,
  ProvinceCitySelector,
  Lottery,
  LotteryBroadcast,
  MyGiftList,
  ShipAddress,
  ShipAddressTpl,
  FormManager,
  ...miloCore,
  miloAlert,
  mobileShare,
  iosInstallReport,
  Customize,
  loadStart,
  loadStop,
  Dialog,
  DJCPay,
};
