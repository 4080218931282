import { RoleSelector as rsCore } from '@tencent/milo-core';
const RoleSelectorMobile = {
  init(option) {
    return new Promise((resolve, reject) => {
      this.getComponent().then((Constructor) => {
        const instance = new Constructor(option);
        resolve(instance);
      })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setPIPInfo: rsCore.setPIPInfo,
  getPIPInfo: rsCore.getPIPInfo,
  getComponent() {
    return new Promise((resolve, reject) => {
      import(/* webpackChunkName: "roleselectormobile"*/ './roleselectormobile.js').then(({ default: RoleSelectorMobile }) => {
        resolve(RoleSelectorMobile);
      }).catch((err) => {
        reject(err);
      });
    });
  },
};

export default RoleSelectorMobile;
