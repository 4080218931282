const tplMobile = `
  <div class="milo-alert-mask">
  </div>
  <div class="milo-alert">
    <div class="milo-alert-box-mobile">
      <div class="milo-alert-header">
        <span class="milo-alert-title">{title}</span>
      </div>
      <div class="milo-alert-content">
        <p>{message}</p>
      </div>
      <div class="milo-alert-footer">
        <div class="btn-cancel" style="color: {cancelColor};">{cancelTitle}</div>
        <div class="btn-confirm" style="color: {confirmColor};">{confirmTitle}</div>
      </div>
    </div>
  </div>
`;

export default tplMobile;
