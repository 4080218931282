export const findNodeByClass = ($el, className) => {
  const childArr = $el.children;
  let res = [];
  if (childArr.length) {
    for (let i = 0; i < childArr.length; i++) {
      if (childArr[i].classList.contains(className)) {
        res.push(childArr[i]);
      }
      if (childArr[i].children.length) {
        res = findNodeByClass(childArr[i], className).concat(res);
      }
    }
  }
  return res;
};

export const _$ = (domQuery) => {
  // 如果是dom直接返回
  // if ('object' === typeof domQuery && Type.isDom(domQuery)) {
  //   return domQuery;
  // }

  if (Object.prototype.toString.call(domQuery) === '[object String]') {
    return document.getElementById(domQuery);
  }
};

export const appendHtml = (dom, html, id, className) => {
  const newNode = document.createElement('div');
  newNode.id = id;
  newNode.className = className;
  newNode.innerHTML = html;
  if (dom && dom.appendChild) {
    dom.appendChild(newNode);
  }
  return newNode;
};

/**
       * 为dom对象设置样式
       * @param {dom} ele dom对象
       * @param {object} styles 样式对象 like:{width:100,height:100}
       * @return undefined
       */
export const setStyle = function (ele, styles) {
  for (const i in styles) {
    ele.style[i] = styles[i];
  }
};

/**
 * 为dom对象获取选定属性的样式
 * @param {dom} ele dom对象
 * @param {string} prop 属性名称
 * @return 属性样式
 */
export const getStyle = function (el, prop) {
  const viewCSS = 'function' === typeof (document.defaultView) // (typeof document.defaultView == 'function')
    ? document.defaultView()
    : document.defaultView;
  if (viewCSS && viewCSS.getComputedStyle) {
    const s = viewCSS.getComputedStyle(el, null);
    return s && s.getPropertyValue(prop);
  }
  return (el.currentStyle && el.currentStyle[prop]) || null;
};
/**
 * 网页内容高度
 * @return {int} 网页内容高度
 */
export const getPageHeight =  function () {
  const h = (window.innerHeight && window.scrollMaxY) ? (window.innerHeight + window.scrollMaxY)
    : (document.body.scrollHeight > document.body.offsetHeight ? document.body.scrollHeight
      : document.body.offsetHeight);
  return h > document.documentElement.scrollHeight ? h : document.documentElement.scrollHeight;
};

/**
 * 网页内容宽度
 * @return {int} 网页内容宽度
 */
export const getPageWidth = function () {
  return (window.innerWidth && window.scrollMaxX) ? (window.innerWidth + window.scrollMaxX)
    : (document.body.scrollWidth > document.body.offsetWidth ? document.body.scrollWidth : document.body.offsetWidth);
};

/**
 * 浏览器可视区域高度
 * @return {int} 网可视区域高度
 */
export const getWinHeight = function () {
  return (window.innerHeight) ? window.innerHeight
    : (document.documentElement && document.documentElement.clientHeight)
      ? document.documentElement.clientHeight
      : document.body.offsetHeight;
};

/**
 * 浏览器可视区域宽度
 * @return {int} 网可视区域宽度
 */
export const getWinWidth = function () {
  return (window.innerWidth) ? window.innerWidth : (document.documentElement && document.documentElement.clientWidth)
    ? document.documentElement.clientWidth : document.body.offsetWidth;
};

/**
 * 获取页面最大高度
 * @return 属性样式
 */
export const getMaxH = function () {
  return (getPageHeight() > getWinHeight() ? getPageHeight() : getWinHeight());
};

/**
 * 获取页面最大宽度
 * @return 属性样式
*/
export const getMaxW = function () {
  return (getPageWidth() > getWinWidth() ? getPageWidth() : getWinWidth());
};

/**
	 * 为DOM对象增加事件
	 * @param {dom} element dom对象
	 * @param {string} type 事件名称
	 * @param {function} type 事件方法
	 * @return {undefined}
*/
export const addEvent = function (el, type, fn) {
  if (window.addEventListener) {
    el.addEventListener(type, fn, false);
  } else {
    el[`on${type}`] = fn;
  }
};
/**
 * 为DOM对象移除事件
 * @param {dom} element dom对象
 * @param {string} type 事件名称
 * @param {function} type 事件方法
 * @return {undefined}
 */
export const removeEvent = function (el, type, fn) {
  if (window.removeEventListener) {
    el.removeEventListener(type, fn, false);
  } else {
    el[`on${type}`] = null;
  }
};

export const isInt = function (n, iMin, iMax) {
  if (!isFinite(n)) {
    return false;
  }
  if (!/^[+-]?\d+$/.test(n)) {
    return false;
  }
  if (iMin != undefined && parseInt(n) < parseInt(iMin)) {
    return false;
  }
  if (iMax != undefined && parseInt(n) > parseInt(iMax)) {
    return false;
  }
  return true;
};
