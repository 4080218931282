const tpl = `
    <div class="milo-alert-mask"></div>
    <div class="milo-alert">
    <div class="milo-alert-box">
        <div class="milo-alert-header">
            <span class="milo-alert-title">{title}</span>
            <div class="milo-alert-close-btn"></div>
        </div>
        <div class="milo-alert-content">
            <p>{message}</p>
        </div>
        <div class="milo-alert-footer">
            <div class="btn-confirm">确认</div>
            <div class="btn-cancel">取消</div>
        </div>
        </div>
    </div>
`;
export default tpl;
