const Login = {
  init(option) {
    return new Promise((resolve, reject) => {
      this.getComponent().then((Constructor) => {
        const instance = new Constructor(option);
        resolve(instance);
      })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getComponent() {
    return new Promise((resolve, reject) => {
      import(/* webpackChunkName: "login"*/ './login.js').then(({ default: LoginManager }) => {
        resolve(LoginManager);
      }).catch((err) => {
        reject(err);
      });
    });
  },
};

export default Login;
